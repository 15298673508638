import React from "react"
import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import ContentBlock from "../../components/ContentBlock"
import SignUpForm from "../../components/SignUpForm/SignUpForm"

const PageSignUpNewYear = () => {
  return (
    <Layout showTrialBanner={false}>
      <PageTitle
        title="New Year. New Software. Try Towbook for 90-Days, Free!"
        description="Experience the best of digital dispatching, mobile applications, sophisticated billing, and our comprehensive reporting, all with no contract, and no setup fees."
      ></PageTitle>
      <ContentBlock>
        <SignUpForm source="newyear" />
      </ContentBlock>
    </Layout>
  )
}

export default PageSignUpNewYear
